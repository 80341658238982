<template>
  <div>
    <HeaderPanel
      title="Booking List"
      :filter="filter"
      placeholder="Search Booking ID."
      @search="getBookingList"
      @export="openExportModal"
      @sidebar="sidebarFilter"
      :hasDropdown="false"
      :hideCreate="false"
      :hasExport="true"
    />
    <div class="bg-white border-red p-2 p-lg-3 mt-3">
      <Table
        :filter="filter"
        :pageOptions="pageOptions"
        :fields="fields"
        :items="items"
        :isBusy="isBusy"
        :rows="rows"
        @filterPage="filterPage"
      />
    </div>
    <SideBarFilter
      ref="sidebarFilter"
      :filter="filter"
      :placeholder="'Search Name'"
      @searchAll="getBookingList"
      @clearFilter="clearFilter"
      :hideStatusFilter="false"
    >
      <template v-slot:filter-option>
        <InputSelect
          title="Booking Name"
          name="booking-name"
          v-model="filter.booking_name"
          :options="bookingList"
          @onDataChange="getStatus()"
          valueField="id"
          textField="name"
          ><template v-slot:option-first>
            <b-form-select-option value="0" disabled
              >-- Select Booking Name --</b-form-select-option
            ></template
          >
        </InputSelect>
        <!-- <div v-if="pleaseSelectBooking"></div> -->
        <InputSelect
          title="Status"
          name="booking-status"
          v-model="filter.status"
          :options="statusList"
          valueField="id"
          textField="name"
          ><template v-slot:option-first>
            <b-form-select-option value="0" disabled
              >-- Select Booking Status --</b-form-select-option
            ></template
          >
        </InputSelect>
        <div class="mt-3 mb-3">
          <div class="font-weight-bold mb-2">Created Date Form</div>

          <div :class="['input-container']">
            <datetime
              :input-style="styleDatetime"
              format="dd/MM/yyyy"
              value-zone="local"
              v-model="filter.create_from"
              placeholder="Please Select Date"
              ref="startDate"
            >
            </datetime>
            <div
              class="icon-primary text-right"
              @click="$refs.startDate.isOpen = true"
            >
              <font-awesome-icon
                icon="calendar-alt"
                class="pointer color-primary"
                color="#B41BB4"
              />
            </div>
          </div>
        </div>
        <div class="mt-3 mb-3">
          <div class="font-weight-bold mb-2">Created Date To</div>
          <div :class="['input-container']">
            <datetime
              :input-style="styleDatetime"
              format="dd/MM/yyyy"
              value-zone="local"
              v-model="filter.create_to"
              placeholder="Please Select Date"
              ref="endDate"
            >
            </datetime>
            <div
              class="icon-primary text-right"
              @click="$refs.endDate.isOpen = true"
            >
              <font-awesome-icon
                icon="calendar-alt"
                class="pointer color-primary"
                color="#B41BB4"
              />
            </div>
          </div>
        </div>
        <InputText
          textFloat="Staff ID"
          placeholder="Staff ID"
          type="text"
          v-model="filter.sale_id"
        />

        <b-form-group>
          <template #label>
            <b>Branch </b><br />
            <b-form-checkbox
              v-model="branchOptions"
              aria-describedby="Status"
              aria-controls="Status"
              @change="branchToggle"
            >
              All
            </b-form-checkbox>
          </template>

          <template v-slot="{ ariaDescribedby }">
            <b-form-checkbox-group
              class="ml-4"
              id="checkbox-group-2"
              stacked
              v-model="filter.branch_list"
              :aria-describedby="ariaDescribedby"
            >
              <b-form-checkbox
                :value="opt.id"
                :id="opt.name"
                v-for="opt of branchList"
                :key="opt.id"
                >{{ opt.name }}
              </b-form-checkbox>
            </b-form-checkbox-group>
          </template>
        </b-form-group>
      </template>
    </SideBarFilter>

    <b-modal v-model="pleaseSelectBooking" centered hide-header>
      <InputSelect
        title="Booking Name"
        name="booking-name"
        v-model="exportBookingName"
        :options="bookingList"
        valueField="id"
        textField="name"
        ><template v-slot:option-first>
          <b-form-select-option value="" disabled
            >-- Select Booking Name --</b-form-select-option
          ></template
        >
      </InputSelect>
      <template #modal-footer class="py-0">
        <div class="d-flex justify-content-between w-100">
          <b-button
            class="btn-cancel btn-width"
            @click="pleaseSelectBooking = false"
            >Cancel</b-button
          >

          <b-button class="main-color btn-save" @click="exportFile">
            Save
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<script>
import Table from "./Table";

export default {
  name: "admin",
  components: {
    Table,
  },
  data() {
    return {
      styleDatetime: {
        width: "100%",
        border: "none",
      },
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      filter: {
        search: "",
        booking_name: 0,
        create_from: null,
        create_to: null,
        status: 0,
        sale_id: null,
        branch_list: [],
        page: 1,
        take: 10,
      },
      isBusy: false,
      rows: 0,
      fields: [
        {
          key: "prefix_id",
          label: "Booking No.",
        },
        {
          key: "username",
          label: "Requester Name / Tel.",
          thClass: "text-center",
          tdClass: "text-left",
        },
        {
          key: "description",
          label: "Description",
          tdClass: "text-left",
        },
        {
          key: "booking_date",
          label: "Booking Date",
        },
        {
          key: "booking_name",
          label: "Booking Name",
        },
        {
          key: "status",
          label: "Status",
        },
        {
          key: "sale_branch",
          label: "Branch / Staff ID",
        },
        {
          key: "created_time",
          label: "Create Date",
        },
        {
          key: "updated_time",
          label: "Last Updated Date",
        },

        { key: "actions", label: "Action" },
      ],
      items: [],
      bookingList: [],
      statusList: [],
      branchOptions: false,
      branchList: [],
      pleaseSelectBooking: false,
      exportBookingName: "",
    };
  },
  created() {
    this.getBookingList();
    this.getBranchList();
    this.getStatus();
  },
  watch: {
    "filter.branch_list"(newValue) {
      if (newValue.length === 0) {
        this.branchOptions = false;
      } else if (newValue.length === this.branchList.length) {
        this.branchOptions = true;
      } else {
        this.branchOptions = false;
      }
    },
    pleaseSelectBooking(val) {
      if (!val) {
        this.exportBookingName = "";
      }
    },
  },
  methods: {
    async getStatus() {
      this.filter.status = 0;
      const res = await this.axios(
        `/booking/getstatus/${this.filter.booking_name}`
      );
      const data = await this.axios(`/booking/getbookingname`);
      this.bookingList = data.data.detail;
      this.statusList = res.data.detail;
    },
    branchToggle(checked) {
      this.filter.branch_list = checked
        ? this.branchList.map((el) => el.id)
        : [];
    },
    async getBranchList() {
      const res = await this.axios(`/booking/get_branch_list`);

      this.branchList = res.data.detail;
    },
    openExportModal() {
      if (this.filter.booking_name) {
        this.exportBookingName = this.filter.booking_name;
        this.exportFile();
      } else {
        this.pleaseSelectBooking = true;
      }
    },
    async exportFile() {
      this.pleaseSelectBooking = false;

      this.$bus.$emit("showLoading");
      let filter = { ...this.filter };

      filter.create_from = filter.create_from
        ? filter.create_from
        : "01/01/0001";
      filter.create_to = filter.create_to ? filter.create_to : "01/01/0001";
      filter.branch_list =
        filter.branch_list.length == 0
          ? null
          : filter.branch_list.length == this.branchList.length
          ? null
          : filter.branch_list;
      filter.booking_name = this.exportBookingName;
      let data = await this.axios.post(`/booking/exportbooking`, filter, {
        responseType: "blob",
      });
      if (data.status == 200) {
        var fileURL = window.URL.createObjectURL(new Blob([data.data]));
        var fileLink = document.createElement("a");
        var dateExcel = this.$moment().format("DD-MM-YYYY");
        let stampName = "booking";
        fileLink.href = fileURL;
        fileLink.setAttribute(
          "download",
          `report-${stampName}-${dateExcel}.xlsx`
        );
        document.body.appendChild(fileLink);
        fileLink.click();
        this.exportBookingName = "";
      } else {
        this.errorAlert("Export Fails");
      }

      this.$bus.$emit("hideLoading");
    },
    async getBookingList() {
      // this.filter.sale_id = null;

      this.isBusy = true;
      let filter = { ...this.filter };
      filter.create_from = filter.create_from
        ? filter.create_from
        : "01/01/0001";
      filter.create_to = filter.create_to ? filter.create_to : "01/01/0001";
      filter.branch_list =
        filter.branch_list.length == 0
          ? null
          : filter.branch_list.length == this.branchList.length
          ? null
          : filter.branch_list;
      await this.$store.dispatch("getBookingReportList", filter);
      let data = this.$store.state.booking.formList;
      if (data.result == 1) {
        this.items = data.detail.reponse;
        this.rows = data.detail.count;
        this.isBusy = false;
        this.isLoading = false;
      } else {
        this.errorAlert();
      }
    },

    sidebarFilter() {
      this.$refs.sidebarFilter.show();
    },
    filterPage(filter) {
      this.filter = filter;
      this.getBookingList();
    },
    clearFilter() {
      this.filter = {
        search: "",
        booking_name: 0,
        create_from: null,
        create_to: null,
        status: 0,
        sale_id: 0,
        branch_list: [],
        page: 1,
        take: 10,
      };
      this.getBookingList();
      this.$refs.sidebarFilter.hide();
    },
  },
};
</script>
<style lang="scss">
::v-deep .vdatetime-popup {
  z-index: 9999 !important;
}
::v-deep .date-container {
  input {
    color: var(--font-main-color) !important;
    border: 1px solid #d8dbe0 !important;
    border-radius: 0.25rem !important;
    height: calc(1.5em + 0.75rem + 2px) !important;
    padding: 0.375rem 0.75rem !important;
  }
}
.input-date {
  position: relative;
  svg {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-75%, 10px);
  }
}
</style>
